import React from 'react';
import styled, {keyframes} from 'styled-components';
import {css} from 'emotion';
import Row from './Row';
import {Spring, animated, Transition} from 'react-spring/renderprops';
import CityList from './citieslists.js';

/* global tw */
const Section = styled.section`
    ${tw`w-screen flex flex-wrap items-center justify-center`};
    min-height: 65rem;
    // height: 50rem;
    
    padding-top: 0rem;
    padding-bottom: 4rem;
    @media (min-width: 992px){
        min-height: 50rem;
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
`
const SectionHeading = styled.h2`
    ${tw`font-bold tracking-tight mb-4 w-full`}
    font-size: ${1.618 + 0.809}rem;
    line-height: 1.1;
    // @media (min-width: 992px){
    //     letter-spacing: -2px;
    //     line-height: ${1.618 * 2}rem;
    // }
`
const SectionHeading1 = styled.h3`
  ${tw`font-medium text-black mt-4 mb-2`}
  font-size: ${1.618}rem;
  color: #000000;
  letter-spacing: -0.4px;
`


const DescriptionWrapper = styled.div`
    ${tw`w-full lg:w-1/2 px-4`}; 

    @media (min-width: 992px){
        // transform: translate3d(0, 100px, 0)
    }
`

const Paragraph = styled.p`
    ${tw`text-grey-dark w-4/5 mt-8 leading-loose`};
    opacity: 0.77;
    // color: #000000;
    font-family: "Poppins";
    @media (min-width: 992px){
        letter-spacing: 0.16px;
        font-size: 1rem;
        line-height: 1.618;
    }
`

const Illustration = styled.img.attrs(({src}) => {
    src = src
})`
    height: 10rem;
    @media(min-width : 992px){
        height: 20rem;
        transform: translate3d(0, 2rem , 0);
    }
`

const progress = keyframes`
    0%{
        width: 0%;
    }
    100%{
        width: 100%;
    }
`
class SectionThree extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            step : 1,
            heading: 'You give us all your details',
            para: 'You tell your destinations, time, car type etc.',
        }
        this.stepsTicker = this.stepsTicker.bind(this);
    }

    componentDidMount(){
        this.stepsTicker(1);
    }

    componentDidUpdate(){


    }

    stepsTicker(start){
        let self = this;
        let iteration = 0;
        self.setState({step: start});
        
        

        let intFunc = setInterval(
            function () {
                if (self.state.step < 6) {
                    self.setState({ step: self.state.step + 1 });
                }
                else {
                    self.setState({ step: 1 });
                    iteration = 0;
                }
                // console.log('step: ' + self.state.step);
                // console.log('Iteration : ' + iteration++);
            },
        8000);

        clearInterval(intFunc);
        
        // setTimeout(() => {  
        //     // intFunc();    
        // }, 3000);

    }

    render(){
        return(
            <Section>
                {/* <div> */}
                <Row>

                    <Row className={css`${tw``}`}>
                    <CityList />
                        <DescriptionWrapper>
                            <SectionHeading> Experience Luxury and Professionalism with Our Exclusive Car Service</SectionHeading>
                            <SectionHeading1>Find. Book. Ride. It's That Simple!</SectionHeading1>
                            <Paragraph>Zyppys car rental service offers the superior alternative to traditional taxi companies, featuring a vast network of local chauffeurs dedicated to delivering five-star service.</Paragraph>
                            {/* <Paragraph>Discover luxury at your fingertips with our luxury car rental platform. From elegant sedans to spacious SUVs, find the perfect ride for every occasion. Experience convenience, style, and sophistication with every booking. Your journey starts here.</Paragraph> */}
                        </DescriptionWrapper>
                        <div className={css`${tw`w-full lg:w-1/2 flex text-right items-center px-12`}`}>
                            <Illustration src={require('../assets/img/zyppyslandingimg.jpg')} 
                            style={{ borderRadius: '20px' }}/>
                        </div>

                    </Row>
                        <Row className={css`${tw`w-full mt-20 relative z-20`}`}>
                            <HowPagination id={1} key={1} active={this.state.step === 1} onClick={()=> this.setState({step: 1})}/>
                            <HowPagination id={2} key={2} active={this.state.step === 2} onClick={()=> this.setState({step: 2})}/>
                            <HowPagination id={3} key={3} active={this.state.step === 3} onClick={()=> this.setState({step: 3})}/>
                            {/* <HowPagination id={4} key={4} active={this.state.step === 4} onClick={()=> this.setState({step: 4})}/> */}
                            {/* <HowPagination id={5} key={5} active={this.state.step === 5} onClick={()=> this.setState({step: 5})}/> */}
                            {/* <HowPagination id={6} key={6} active={this.state.step === 6} onClick={()=> this.setState({step: 6})}/>  */}
                        </Row>
                        <Row className={css`${tw`w-full relative z-10`}`}>

                            <Transition
                                native
                                items={this.state.step ===1 }
                                from={{ opacity: 0 }}
                                enter={{opacity: 1}}
                                leave={{ opacity: 0 }}>
                                
                                {show => show && (props => 
                                    <HowDescription style={props}>
                                        <h3> Ride details</h3>
                                        <p> simply enter your pickup and dropoff details</p>
                                    </HowDescription>
                                )}
                            </Transition>
                            <Transition
                                native
                                items={this.state.step ===2 }
                                from={{ opacity: 0 }}
                                enter={{ opacity: 1 }}
                                leave={{ opacity: 0 }}>
                                
                                {show => show && (props => 
                                    <HowDescription style={props}>
                                        <h3>Select Vehicle</h3>
                                        {/* <p>From all the top car rental companies in India.</p> */}
                                        <p>Select the vehicle class that suits your needs</p>
                                    </HowDescription>
                                )}
                            </Transition>
                            {/* <Transition
                                native
                                items={this.state.step ===3 }
                                from={{ opacity: 0 }}
                                enter={{ opacity: 1 }}
                                leave={{ opacity: 0 }}>
                                
                                {show => show && (props => 
                                    <HowDescription style={props}>
                                        <h3>We compare and show you the best price by default</h3>
                                        <p>But also, you can change this filter to show best rated instead of best price</p>
                                    </HowDescription>
                                )}
                            </Transition> */}
                            {/* <Transition
                                native
                                items={this.state.step ===4 }
                                from={{ opacity: 0 }}
                                enter={{ opacity: 1 }}
                                leave={{ opacity: 0 }}>
                                
                                {show => show && (props => 
                                    <HowDescription style={props}>
                                        <h3>Best Coupon is applied by default</h3>
                                        <p>Throughout all the promotions, we pick and apply the most favorable coupon by default. You don't have to do a thing!</p>
                                    </HowDescription>
                                )}
                            </Transition> */}
                            <Transition
                                native
                                items={this.state.step ===3 }
                                from={{ opacity: 0 }}
                                enter={{ opacity: 1 }}
                                leave={{ opacity: 0 }}>
                                
                                {show => show && (props => 
                                    <HowDescription style={props}>
                                        <h3>Book & confirm</h3>
                                        {/* <p>Review your vendor, price breakdown, terms etc</p> */}
                                        <p>Confirm us by completing the booking process.</p>
                                    </HowDescription>
                                )}
                            </Transition>
                            {/* <Transition
                                native
                                items={this.state.step ===4 }
                                from={{ opacity: 0 }}
                                enter={{ opacity: 1 }}
                                leave={{ opacity: 0 }}>
                                
                                {show => show && (props => 
                                    <HowDescription style={props}>
                                        <h3>Easy Drop-Off</h3>
                                        <p>Return the car hassle-free at your preferred location.</p>
                                    </HowDescription>
                                )}
                            </Transition> */}

                            
                        </Row>
                    {/* <Row className={css`${tw`w-full mt-40 relative z-20`}`}>
                        <HowDescription>
                        <span>
                            <span>Contactless Travel – The New Normal - Post-COVID -19</span>
                            <h1>Rental Car &  Self Drive Cars Rental in India</h1>
                            <span>If traveling is a lot more than a hobby and a little less than addiction, then Zyppys is the app for you! Why should I book only through Zyppys?</span>
                            <span>It is India's first specialized car rental marketplace. Zyppys App is a one-stop-shop for all your
                            <h2 className={css`${tw`inline-block text-xs`}`}>
                                    <a href="https://zyppys.com"> rental car, car rentals</a>
                                </h2>,
                            <h2 className={css`${tw`inline-block text-xs`}`}>
                                    <a href="https://zyppys.com/self-drive-cars"> self-drive cars</a>
                                </h2>,
                            <h2 className={css`${tw`inline-block text-xs`}`}>
                                    <a href="https://zyppys.com/outstation-cabs"> outstation cab</a>
                                </h2>, cab rental, home to office transportation,
                            <h2 className={css`${tw`inline-block text-xs`}`}>
                                    <a href="https://zyppys.com/self-drive-car-rental-in-hyderabad"> rental car</a>
                                </h2>,
                             <h2 className={css`${tw`inline-block text-xs`}`}>
                                    <a href="https://zyppys.com"> in India</a>
                                </h2>, self drive car rental in India, safe rental cars after lockdown, clean cars, private transportation after lockdown and daily transportation need post-
                             <h2 className={css`${tw`inline-block text-xs`}`}>
                                    <a href="https://www.who.int" target="_blank"> COVID -19 </a>
                                </h2>. It makes it easy for you to browse, compare, and rent your favorite car instantly at the best price from top car rental companies in India (savaari, ahataxis, and more). You can choose rental cars that come with the driver, self-drive, from and to the airport, Car for Outstation, or select point to point daily transportation needs. You can choose from different cars, different pricing to suit your budget and we will ensure you have a safe contactless journey protecting you and your family.</span>
                                </span>

                        </HowDescription>
                    </Row> */}
                    </Row>
                {/* </div> */}

            </Section>
        );
    }
}
    

export default SectionThree;

const HowDescription = styled(animated.div)`
    ${tw`px-4 absolute pin-l z-0`};
    top: 4%;
    width: 100%;
    @media(min-width: 992px){
        width: 100%;
    }
    h3{
        ${tw`font-medium text-black mt-12 mb-2`}
        font-size: 1.618rem;
        color: #000000;
        letter-spacing: -0.4px;
    }
    p{
        opacity: 0.77;
        font-family: "Poppins";
        font-size: 1rem;
        color: #66686A;
        letter-spacing: 0.01rem;
        line-height: 1.618;
    },
    span{
        font-size: 0.5rem !important;
        color: #fff !important;
    }
    h2{
        font-size: 0.5rem;
        display: inline-block;
    }
    h4{
        font-size: 0.75rem;
        display: inline-block;
    }
    h5{
        font-size: 0.75rem;
        display: inline-block;
    }
    a{
        font-size: 0.5rem !important;
        color: #fff;
        appearance: none !important;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        cursor: pointer !important;
    }
`
const HowPaginationWrapper = styled.div`
    ${tw`flex-1 relative mx-1 lg:mx-4 relative overflow-hidden cursor-pointer`};    
    p{
        ${tw`font-black text-black mb-2 opacity-10`};
        ${props => props.active && tw`opacity-100`}
        font-size: 1.618rem;
    }
`
const Line = styled.div`
    ${tw`relative z-0 pin-b pin-x h-full h-1 overflow-hidden bg-black opacity-10`};
    ${props => props.active && tw`opacity-100`};

    ::after{
        content: '';
        position: absolute;
        display: ${props => props.active ? 'block': 'hidden'};
        
        animation: 8s ${progress} ease-in-out;
        height: 100%;
        background: black;
        z-index: 1
    }
`
const HowPagination = (props) => (
    <HowPaginationWrapper onClick={props.onClick} key={props.id} active={props.active}>
        <p>0{props.id ? props.id : 1}</p>
        <Line active={props.active}/>
    </HowPaginationWrapper>
);