import React from 'react';
import SliderModal from './SliderModal';
import {css} from 'emotion';

/*global tw */

const PackagesModal = (props) => (
    <SliderModal active={props.active}>
       {props.type == 'hourly' && <div className={css`${tw`w-full flex items-center flex-wrap px-6 py-8`}`}>
            <h3 className={css`${tw`w-full lg:px-8 font-light lg:text-3xl tracking-tight mb-2 text-grey`}`}>
                How long do you need the rental? <br />
            </h3>
            <div className={css`${tw`w-full flex items-start lg:px-8`}`}>
                <ul className={css`${tw`list-reset w-full`}`}>
                    {/* <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} 
                        onClick={()=> props.onClick(5, false)}>4 hrs, 40kms
                    </li> */}
                    <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} 
                        onClick={()=> props.onClick(6, false)}>8 hrs, 80kms
                    </li>
                    {/* <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} 
                        onClick={()=> props.onClick(7, false)}>12 hrs, 120kms
                    </li> */}
                </ul>
            </div>
        </div>}
       {props.type === 'airport' && <div className={css`${tw`w-full flex items-center flex-wrap px-6 py-8`}`}>
            <h3 className={css`${tw`w-full lg:px-8 font-light lg:text-3xl tracking-tight mb-2 text-grey`}`}>
                Choose airport pickup/dropoff <br />
            </h3>
            <div className={css`${tw`w-full flex items-start lg:px-8`}`}>
                <ul className={css`${tw`list-reset w-full`}`}>
                    <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} 
                        onClick={()=> props.airportTypeClick('Airport Pickup', false)}>Airport Pickup
                    </li>
                    <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} 
                        onClick={()=> props.airportTypeClick('Airport Dropoff', false)}>Airport Dropoff
                    </li>
                </ul>
            </div>
        </div>}
    </SliderModal>

);

export default PackagesModal;