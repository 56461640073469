import React from 'react';
import { css } from 'emotion';
import Link from 'gatsby-link';
import Row from './Row';
import fancyCurves from '../assets/img/fancy-curves-divider-down.svg';
import DownloadAppButton from './appComponents/DownloadAppButton';
import styled from 'styled-components';

const bgImg = require('../assets/img/footer-bg.svg');
/* global tw */
const styles = {
    footer: css`
        ${tw`w-screen relative h-auto py-12`};
        background: black;
        background-size: cover;
        background-repeat: no-repeat;
        ::before{
            content: "";
            display: block;
            position: absolute;
            top: -9.85rem;
            height: 10rem;
            width: 100%;
            background-image: url(${fancyCurves});
            background-size: cover;
            background-repeat: no-repeat;
            transform: rotate(180deg);
        }

    `,
    pText: css`
        ${tw`text-left lg:text-left text-white font-hairline tracking-tight opacity-75`};
        margin-top: -1.5rem;
        margin-bottom: 1rem;
        }
    `,
   hText: css`
    ${tw`text-white lg:w-1/2 w-full opacity-75 font-bold tracking-wide`};
    margin-top: -0.25rem;
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Optional: Adds '...' if the text exceeds container */
`,

    seoText: css`
        ${tw`text-black w-full appearance-none no-underline`};
        font-size: 0.01rem;
        }
    `,
}

const SmallFooterText = styled.div`
    ${tw`text-white opacity-50 text-sm`};
`

const SmallFooterText1 = styled.div`
    ${tw`text-left lg:text-left text-white font-hairline tracking-tight opacity-75 `};
`

const keywords = [
    { from: "Hyderabad", to: "Bangalore" },
    { from: "hyderabad", to: "Yadagirigutta" },
    { from: "hyderabad", to: "srisailam" },
    { from: "hyderabad", to: "Karimnagar" },
    { from: "hyderabad", to: "vijayawada" },
    { from: "hyderabad", to: "warangal" },
    { from: "bangalore", to: "Chikmagalur" },
    { from: "bangalore", to: "mysore" },
    { from: "bangalore", to: "coorg" },
    { from: "bangalore", to: "ooty" },
    { from: "bangalore", to: "nandi hills" },
    { from: "bangalore", to: "pondicherry" },
    { from: "bangalore", to: "tirupati" },
    { from: "bangalore", to: "kodaikanal" },
    { from: "bangalore", to: "chennai" },
    { from: "bangalore", to: "wayanad" },
    { from: "delhi", to: "gurgaon" },
    { from: "delhi", to: "lansdowne" },
    { from: "delhi", to: "jaipur" },
    { from: "delhi", to: "rishikesh" },
    { from: "delhi", to: "agra" },
    { from: "delhi", to: "shimla" },
    { from: "delhi", to: "chandigarh" },
    { from: "delhi", to: "kasol" },
    { from: "delhi", to: "mathura" },
    { from: "mumbai", to: "pune" },
    { from: "mumbai", to: "alibaug" },
    { from: "mumbai", to: "nashik" },
]

const selfDriveKeywords = [
    "hyderabad", "bangalore", "chennai", "mysore", "secunderabad"
]

const Footer = (props) => (
    <footer className={styles.footer}>
        <Row justifyBetween>
            {/* <>
            <h4 className={styles.hText}>
                One stop shop
            </h4>
            <p className={styles.pText}>
                You no longer have to go through multiple search results or apps and painstakingly compare prices and choices to find the suitable option for you. Our platform does all that for you and much more.
            </p>
            <h4 className={styles.hText}>
                Easy to book
            </h4>
            <p className={styles.pText}>
                Our platform has a very simple UI, easy navigation and booking can be completed with simple steps of search, compare and submit.
            </p>
            <h4 className={styles.hText}>
                Pricing options
            </h4>
            <p className={styles.pText}>
                Our platform being a marketplace will offer you the best available discounts, all at one place. First time users get up to &#8377;250 off.
            </p>

            <h4 className={styles.hText}>
                Do you have a strong drive?
            </h4>
            <p className={styles.pText}>
                If you have a strong drive to drive your own vehicle, our platform is the best place to visit. We also provide you options to rent self-driven cars, in addition to chauffeur driven options. 
            </p>

            <h4 className={styles.hText}>
                Safety and customer satisfaction
            </h4>
            <p className={styles.pText}>
                While we add more providers to our platform, we ensure safety as the highest priority. Any questions or concerns will be addressed by our 24X7 call center.
            </p>

            <h4 className={styles.hText}>
                Our popular routes
            </h4>
            <p className={styles.pText}>
                Once a customer, always a customer. With more than 80% repeat customers, our most popular routes are
            </p>
            <p className={css`${tw`text-left lg:text-left text-white font-hairline tracking-tight opacity-75 `}`}>
                Hyderabad To Bangalore , Hyderabad to Yadagirigutta , Bangalore To Chikmagalur , Hyderabad To Karimnagar , Bangalore To Mysore , Hyderabad To Vijayawada , Hyderabad To Warangal , Bangalore To Coorg , Bangalore To Ooty , Bangalore To Nandi , Bangalore To Pondicherry , Bangalore To Tirupati , Hyderabad To Srisailam , Bangalore To Kodaikanal , 
                Bangalore To Chennai , Bangalore To Wayanad.
            </p>
            </> */}
     <div className={styles.container}>
    <div>
        <h2 className={styles.hText}>Vacations:</h2>
        <h3 className={styles.hText } style={{ marginLeft:'40px'}}>Stress-free travel:</h3>
        <p className={styles.pText}style={{ marginLeft:'40px'}}>
            With a chauffeur handling the driving, you can relax and enjoy the scenery.
        </p>
        <h3 className={styles.hText}style={{ marginLeft:'40px'}}>Local expertise:</h3>
        <p className={styles.pText}style={{ marginLeft:'40px'}}>
            Chauffeurs often have knowledge of the local area, helping you discover hidden gems or navigate tourist spots easily.
        </p>
        <h3 className={styles.hText}style={{ marginLeft:'40px'}}>Comfort and convenience:</h3>
        <p className={styles.pText}style={{ marginLeft:'40px'}}>
            Avoid the hassle of parking, traffic, and directions, letting you fully immerse in your vacation experience.
        </p>
    </div>
    <br />
    <div>
        <h2 className={styles.hText}>Business Trips:</h2>
        <h3 className={styles.hText}style={{ marginLeft:'40px'}}>Time management:</h3>
        <p className={styles.pText}style={{ marginLeft:'40px'}}>
            A chauffeur allows you to focus on work, take calls, or prepare for meetings while commuting.
        </p>
        <h3 className={styles.hText}style={{ marginLeft:'40px'}}>Professionalism:</h3>
        <p className={styles.pText}style={{ marginLeft:'40px'}}>
            Arriving in a chauffeur-driven car enhances your business image and adds a touch of sophistication.
        </p>
        <h3 className={styles.hText}style={{ marginLeft:'40px'}}>Efficiency:</h3>
        <p className={styles.pText}style={{ marginLeft:'40px'}}>
            With a professional at the wheel, you can optimize your schedule, ensuring punctuality for meetings or events.
        </p>
    </div>
    <br />
    <div>
        <h2 className={styles.hText}>Special Occasions:</h2>
        <h3 className={styles.hText}style={{ marginLeft:'40px'}}>Luxury experience:</h3>
        <p className={styles.pText}style={{ marginLeft:'40px'}}>
            For weddings, anniversaries, or celebrations, a chauffeur-driven car adds an element of elegance and class to the event.
        </p>
        <h3 className={styles.hText}style={{ marginLeft:'40px'}}>Convenience:</h3>
        <p className={styles.pText}style={{ marginLeft:'40px'}}>
            You and your guests can relax, knowing transportation is taken care of, allowing everyone to enjoy the occasion.
        </p>
        <h3 className={styles.hText}style={{ marginLeft:'40px'}}>Safety:</h3>
        <p className={styles.pText}style={{ marginLeft:'40px'}}>
            Whether it’s a night out, party, or special event, having a designated chauffeur ensures safety, especially if alcohol is involved.
        </p>
    </div>
    <p className={styles.pText} style={{ marginTop: '30px', marginLeft:'40px'}}>
        Chauffeur services provide a blend of comfort, convenience, and style, making any occasion more enjoyable and hassle-free.
    </p>
</div>
    
            {/* <Row className={css`${tw``}`}>
                {keywords.map((word, i) => {
                    if (i !== keywords.length - 1) {
                        return (
                            <SmallFooterText1 className={css`${tw`cursor-pointer capitalize`}`} onClick={() => { props.onPlacePress("chauffeur", word.from, word.to); window.scrollTo(0, 0) }}>{word.from} to {word.to},&nbsp;</SmallFooterText1>
                        )
                    }
                    else {
                        return (
                            <SmallFooterText1 className={css`${tw`cursor-pointer capitalize`}`} onClick={() => { props.onPlacePress("chauffeur", word.from, word.to); window.scrollTo(0, 0) }}>{word.from} to {word.to}.&nbsp;</SmallFooterText1>
                        )
                    }
                })
                }
            </Row> */}
            <h4 className={css`${tw`text-white lg:w-1/2 w-full font-normal tracking-wide`}`}>
                Download app from play store or app store to enjoy greater benefits.
            </h4>
        </Row>
        {/* <Row justifyBetween className={css`${tw`mt-4`}`}>
            <p className={css`${tw`text-left lg:text-left text-white font-medium tracking-tight opacity-75 `}`}>Top Self Drive Routes </p>
            <Row className={css`${tw``}`}>
                {selfDriveKeywords.map((word)=>(
                    <SmallFooterText className={css`${tw`cursor-pointer capitalize`}`} onClick={()=>{props.onPlacePress("self",word)}}>Self Drive Cars from {word},&nbsp;</SmallFooterText>
                ))
                }
            </Row>
        </Row> */}

        <Row justifyBetween>

            <div className={css`${tw`w-full lg:w-1/2 mt-4`}`}>
                <img className={css`${tw``}; height: 3rem;`}
                    src={require('../assets/img/logo-with-light-text.svg')}
                    alt="Zyppys Logo" />
                <p className={css`${tw`text-white opacity-60 leading-normal mt-4`}`}>
                    T-Hub<br />
                    Gachibowli,Hyderabad<br />
                    Telangana - 500081
                </p>
            </div>
            <div className={css`${tw`w-full lg:text-right lg:w-1/2`}`}>
                <p className={css`${tw`text-left lg:text-right text-white font-medium tracking-tight opacity-75 `}`}>Any Questions?</p>
                <a href="tel:+918688901902" className={css`${tw`text-left lg:text-right text-white font-bold tracking-tight no-underline opacity-75 hover:opacity-100`}`}>Call Us On: +91 8688 901 902 </a><br />
                {/* <Link to="/partnerWithUs/" className={css`${tw`text-left lg:text-right text-white font-medium tracking-tight opacity-75 hover:opacity-100`}; text-decoration:none`}>Partner With Us</Link><br/> */}
                {/* <a href="https://partnerwithuslogin.zyppys.com/" className={css`${tw`text-left lg:text-right text-white font-medium tracking-tight opacity-75 hover:opacity-100`}; text-decoration:none`} target="_blank">Partner With Us Login</a><br /> */}
                {/* <Link to="/blogs/" className={css`${tw`text-left lg:text-right text-white font-medium tracking-tight opacity-75 hover:opacity-100`}; text-decoration:none`}>Blogs</Link><br /> */}
                {/* <Link to="/faq/" className={css`${tw`text-left lg:text-right text-white font-medium tracking-tight opacity-75 hover:opacity-100`}; text-decoration:none`}>FAQ</Link><br /> */}
                <Link to="/aboutus/" className={css`${tw`text-left lg:text-right text-white font-medium tracking-tight opacity-75 hover:opacity-100`}; text-decoration:none`}>About Us</Link><br />
                <Link to="/partnerwithus/" className={css`${tw`text-left lg:text-right text-white font-medium tracking-tight opacity-75 hover:opacity-100`}; text-decoration:none`}>Partner With Us</Link>


            </div>
        </Row>
        <Row justifyBetween className={css`${tw`py-6`}; border-bottom: 1px solid rgba(255,255,255,0.2)`}>
            {/* <div className={css`${tw`w-full lg:w-1/4`}`}> */}
            <h3 className={css`${tw`text-white lg:w-1/2 w-full opacity-75 font-normal tracking-wide`}`}></h3>
            <DownloadAppButton className={css`${tw`opacity-60`}`} />
            {/* </div>  */}
        </Row>
        <Row justifyBetween className={css`${tw`mt-6 text-grey`}`}>
            <SmallFooterText>An entity of Twilight IT Professionals Private Limited <br />
                CIN: U72200TG2014PTC093906
            </SmallFooterText>
            <div className={css`${tw`lg:text-right`}`}>
                <SmallFooterText><Link to="/terms/" className={css`${tw`decoration-none text-white`}`}>Terms &amp; Conditions</Link></SmallFooterText>
                <SmallFooterText>&copy; Copyrights Reserved.</SmallFooterText>
            </div>
        </Row>
        <Row>
            <div className={styles.seoText}>
                <p>Contactless Travel – The New Normal - Post-COVID -19</p>
                <h1>Rental Car &  Self Drive Cars Rental in India</h1>
                <p>If traveling is a lot more than a hobby and a little less than addiction, then Zyppys is the app for you! Why should I book only through Zyppys?</p>
                <p>It is India's first specialized car rental marketplace. Zyppys App is a one-stop-shop for all your <h2 className={css`${tw`inline-block`}`}><a href="https://zyppys.com">rental car, car rentals</a></h2>, <h2 className={css`${tw`inline-block`}`}><a href="https://zyppys.com/self-drive-cars">self-drive cars </a></h2>,  <h4 className={css`${tw`inline-block`}`}><a href="https://zyppys.com/outstation-cabs">outstation cab</a></h4>, cab rental, home to office transportation, <h3 className={css`${tw`inline-block`}`}><a href="https://zyppys.com/self-drive-car-rental-in-hyderabad">rental car in India,</a></h3> self drive car rental in India, safe rental cars after lockdown, clean cars, private transportation after lockdown and daily transportation need post- <h5 className={css`${tw`inline-block`}`}><a href="https://www.who.int" target="_blank">COVID -19 </a></h5>. It makes it easy for you to browse, compare, and rent your favorite car instantly at the best price from top car rental companies in India (savaari, ahataxis, and more). You can choose rental cars that come with the driver, self-drive, from and to the airport, Car for Outstation, or select point to point daily transportation needs. You can choose from different cars, different pricing to suit your budget and we will ensure you have a safe contactless journey protecting you and your family.</p>
            </div>
        </Row>
    </footer>
);

export default Footer;
