import React from "react";

const cities = [
  "Bangalore", "Hyderabad", "Delhi", "Gurgaon",
  "Ahmedabad", "Jaipur", "Udaipur", "Pune", "Chandigarh", "Amritsar",
];

const CityList = () => {
  return (
    <div style={styles.container}>
      <div style={styles.fixedHeight}>
        <div style={styles.row}>
          <span style={styles.label}>Our Service Locations</span>
          <div style={styles.cityWrap}>
            {cities.map((city, index) => (
              <div key={index} style={styles.cityItem}>
                <span style={styles.dot}>●</span>
                <span style={styles.cityName}>{city}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    maxWidth: "1200px",
    padding: "20px",
    borderRadius: "8px",
    textAlign: "left",
    margin: "auto",
    background: "transparent",
  },
  fixedHeight: {
    minHeight: "60px",
  },
  row: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  label: {
    fontSize: "25px",
    fontWeight: "500",
    color: "black",
  },
  cityWrap: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    alignItems: "center",
  },
  cityItem: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    padding: "4px 8px",
    borderRadius: "4px",
    background: "transparent",
  },
  dot: {
    fontSize: "12px",
    color: "black",
  },
  cityName: {
    fontSize: "15px",
    fontWeight: "400",
    color: "black",
  },
};

export default CityList;
