import React from 'react';
// import styled from 'react-emotion';
import { css } from 'emotion';
import styled, { keyframes } from 'react-emotion';
import PartnersSlider from './PartnersSlider';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import BlackOverlay from './appComponents/BlackOverlay';
import SliderModal from './appComponents/SliderModal';
import PopupSlider from './appComponents/PopupSlider';
import Helmet from 'react-helmet';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {
    addStop,
    addStopAtIndex,
    editStop,
    pushStopAtIndex,
    updateDestLocation,
    updatePickupLocation,
    updateCityOfStop,
    updateRideDistance,
    updateRideDuration,
    addFirstStop,
    fetchLocationByLatLong,
    updateDestCity,
    updateRideStartDate,
    updateStartTime,
    updateRideEndDate,
    updateEndTime,
    updateTripType,
    removeLastStop,
    updateRentalType,
    updateLeadTime
} from '../actions/rideActions';
import Geocode from "react-geocode";
// import InputMoment from 'input-moment';
import 'react-dates/initialize';
import Moment from 'moment';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { Transition, animated, interpolate } from 'react-spring/renderprops';
import { navigate, Link } from 'gatsby';
import PlacesModal from './appComponents/PlacesModal';
import DateTime from './appComponents/DateTime';
import Select from 'react-select';
import DropDown from 'react-dropdown';
import 'react-dropdown/style.css';
import RentingChoiceTab from './appComponents/RentingChoiceTab';

import CarIcon from '../components/SvgComponents/car-icon.svg';
import OSCabIcon from '../assets/img/illustrations/outstationRentals.svg';
import SDCabIcon from '../assets/img/illustrations/selfDrivenRentals.svg';
import ATCabIcon from '../assets/img/illustrations/airportTransfer.svg';
import HRCabIcon from '../assets/img/illustrations/hourlyRentals.svg';
import HeroImage from '../assets/img/hero-bg-snow-road.png';


/* global tw */

const styles = {

    section: css`
        ${tw`relative flex flex-wrap justify-center w-screen lg:h-screen mx-auto pin-t mt-0 pb-12 h-auto`};
        height: 50rem;
    `,
    bgImage: css`
        ${tw`absolute z-back pin-x h-full w-full bg-trans`};
        top: -15vh;
        // background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 10%, #000000 50%);
        // background-image: linear-gradient(6deg, rgba(38,21,195,0.55) 25%, rgba(200,10.9,215, 0.55) 74%);
        // border-radius: 0 0 50% 50%;

    `,
    row: css`
        ${tw`w-full flex flex-wrap justify-between items-center px-6 lg:px-0 -mx-2 lg:mx-auto`};   
        max-width: 82rem;
        @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            //Retina Specific
            max-width: 75rem;
        }
    `,
    title: css`
        ${tw``};
        margin: 0;
        font-size: 14vw;
        @media(min-width: 1000px){
            font-size: 5rem;
        }
    `,
    row: css`
        ${tw`w-full flex flex-wrap max-w-row mx-auto`};
    `,
    mainHeading: css`
        ${tw`my-0 w-3/5 text-grey-dark font-bold lg:w-auto`};
        // color: #F0F2F5;
        font-size: 42px;
        color: #414242;
        letter-spacing: -1.94px;
        font-size: 1.618rem;
        @media(min-width: 992px){
            font-size: 41.891223733px;
            letter-spacing: -1.618px;
        }
        span{
            font-size: 1rem;
            letter-spacing: 0;
            @media(min-width: 992px){
                font-size: calc(41.891223733px/1.618);
                letter-spacing: -0.809px;
            }
        }
    `,
    mainParagraph: css`
        ${tw`opacity-50 font-sans w-full mt-2 lg:w-2/3`}
        font-size: 0.8rem;
        @media(min-width: 992px){
            font-size: 1rem;
            line-height: 1.618rem;
        }
        color: #F0F2F5;
        letter-spacing: 0;
    `,
    bookingForm: css`
        ${tw`items-center flex flex-wrap bg-white relative mx-auto overflow-hidden `};
        border-radius: 8.09px;
        box-shadow: 0 6px 24px rgba(0,0,0,0.2);
        height: max-content;
        width: 98vw;
        // max-height: initial;
        @media (min-width: 992px){
            width: 80.90vw;
            // height: 38.195302843vh;
        }
    
    `,
    datePicker: css`
        ${tw`list-reset`};
        li{
            ${tw`my-4 py-1 lg:py-2 px-1 lg:px-4 cursor-pointer`};
            &:hover{
                ${tw`bg-grey-light`}
            }
        }
        li.active{
            ${tw`bg-grey-dark text-white`};
        }
    
    `,
    autocompleteSuggestions: css`
        ${tw`list-reset w-full`};
        li{
            ${tw`py-2 px-1 my-1 text-grey-darker font-sans lg:text-lg hover:bg-grey-light`};

        }
    `,
    dateTimeScrollWrapper: css`
        ${tw`w-gr1/3 lg:text-2xl mr-6 lg:px-2 py-2 overflow-y-scroll`}; 
        height:30vh;
        -webkit-mask-image: -webkit-gradient(linear, left 90%,left bottom, 0%(rgba(0,0,0,1)), 25%(rgba(0,0,0,0)),75%(rgba(0,0,0,0)), 100%(rgba(0,0,0,1)))
    `

}

function convertTime24to12 (time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    console.log(time);
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
}


function convertTime12to24(time12h) {
    
  
    let [time,modifier] = time12h.split(' ');
    // alert(time+""+modifier);
    if(modifier === "AM"){
      let [hrs,mins] = time.split(":");
      if(hrs === '12'){
        hrs = '00';
      }
      return hrs + ':' + mins + ":00";
    }
    else if(modifier === "PM"){
      let [hrs,mins] = time.split(":");
      if(Number(hrs) < 12){
        hrs = Number(hrs) + 12;
      }
      return hrs + ':' + mins + ":00";
    }
}

const CancelToken = axios.CancelToken;
let cancel, i = 0;
// old key AIzaSyAEarG8tKd2cFXWtDkAfBOrymFUCfjuFok
// zyppys portal AIzaSyDmSzYU3JlM-AjP_XL9spDTO2A7iWfilUg
Geocode.setApiKey("AIzaSyDrm-YN3aa0Lj3VMz-hD0_IExL2BYZ06qA");

const hourlyOptions = [
    { value: 1, label: '2hrs, 20kms' },
    { value: 2, label: '8hrs, 80kms' },
    { value: 3, label: '12hrs, 120kms' },

]


class SectionOne extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            isRoundtrip: false,
            isOverlayActive: false,
            isSliderModalActive: false,
            formEditing: false,
            isPlace: false,
            isStartDate: false,
            dateEditing: false,
            isPickupActive: false,
            isDestActive: false,
            pickupAddress: null,
            destinationAddress: null,
            pickupLocation: "",
            destLocation: '',
            pickupCity: '',
            destCity: '',
            pickupPlaces: [],
            destPlaces: [],
            startDate: Moment(),
            endDate: Moment(),
            editStartDate: false,
            editingEndDate: false,
            editingStartDate: false,
            destPlaces: [],
            startDates: [],
            startTimes: [],
            address: '',
            rentalType: 1,
            showHourlyPackageOptions: false,
            rentalPackage:'4 hrs, 40kms',
            tagline:''
        }
        this.textInput = React.createRef();
        // this.textInput = null;
        this.handleFormEdit = this.handleFormEdit.bind(this);
        this.useGeolocation = this.useGeolocation.bind(this);
        this.searchInput = null;
        this.getDistanceBetweenStops = this.getDistanceBetweenStops.bind(this);
    }

 
    componentWillMount() {
        
        // eslint-disable-next-line
        if (typeof analytics === `undefined`) {
            return;
        }
        // eslint-disable-next-line
        analytics.page('\webapp3.0', { category: 'navigation', label: 'Homepage' });

        let ride = this.props.ride;
        if(ride.endTime === ''){
            let minutes = 30 - (Moment().minute() % 30);
            let minStartDate = Moment().add(2, 'h').add(minutes, 'm').seconds('0');
            let minEndDate = Moment(minStartDate).add(2,'h');
            let endDate = Moment(minEndDate).hours(23).minute(30);
            this.props.dispatch(updateRideStartDate(Moment(minStartDate).format("DD-MM-YYYY")));
            this.props.dispatch(updateStartTime(Moment(minStartDate).format("HH:mm:ss")));
            this.props.dispatch(updateRideEndDate(endDate.format("DD-MM-YYYY")));
            this.props.dispatch(updateEndTime(convertTime12to24(Moment(endDate).format("hh:mm A"))));     
        }
        if(this.props.ride.rentalType===4){
            this.setState({rentalPackage:'2 hrs, 20kms', rentalType: 4});
        }
        else if(this.props.ride.rentalType===5){
            this.setState({rentalPackage:'4 hrs, 40kms', rentalType: 5});
        }
        else if(this.props.ride.rentalType===6){
            this.setState({rentalPackage:'8 hrs, 80kms', rentalType: 6});
        }
        else if(this.props.ride.rentalType===7){
            this.setState({rentalPackage:'12 hrs, 120kms', rentalType: 7});
        }  
    }

    handleFormEdit(type) {
        let self = this;
        if (type === 'pickup') {
            setTimeout(function () {
                
                if (self.refs.myInput) ReactDOM.findDOMNode(self.refs.myInput).focus();
            }, 500);
            this.setState({
                isOverlayActive: true,
                isSliderModalActive: true,
                isPickupActive: true,
                isDestActive: false,
                editingStartDate: false,
                editingEndDate: false,
                text: this.state.pickupLocation,
                address: ""
            });
        }
        else if (type === 'dest') {
            setTimeout(function () {
                
                if (self.refs.myInput) ReactDOM.findDOMNode(self.refs.myInput).focus();
            }, 500);
            this.setState({
                isOverlayActive: true,
                isSliderModalActive: true,
                isDestActive: true,
                isPickupActive: false,
                editingStartDate: false,
                editingEndDate: false,
                text: this.state.destLocation,
                address: ""
            });
        }
        else if (type === 'startDate') {
            this.setState({
                isOverlayActive: true,
                isSliderModalActive: true,
                editingStartDate: true,
                editingEndDate: false,
                isPickupActive: false,
                isDestActive: false,
            });

        }
        else if (type === 'endDate') {
            this.setState({
                isOverlayActive: true,
                isSliderModalActive: true,
                editingEndDate: true,
                editingStartDate: false,
                isPickupActive: false,
                isDestActive: false,
            });
        }
        else if (type === 'tripType') {
            this.setState({isRoundtrip: !this.state.isRoundtrip}, function(){

                //toggling with destination loc
                if(self.props.ride.dest.loc !== ""){
                    self.toggleTripType();
                }
            });
        }
    }


    useGeolocation() {
        
        let flag, city, coords;
        let self = this;
        let options = {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
        };
        //eslint-disable-next-line
        if (typeof navigator !== `undefined`) {
            const geolocation = navigator.geolocation;
            geolocation.getCurrentPosition(success, error, options);
            function success(pos) {
                
                let crd = pos.coords;
                coords = crd;
                let latitude = coords.latitude;
                let longitude = coords.longitude;
                Geocode.fromLatLng(latitude, longitude)
                    .then(response => {
                        console.log(response);
                        let result = response.results[0];
                        self.setState({ pickupAddress: result.formatted_address });
                        self.props.dispatch(updatePickupLocation({ name: result.formatted_address, locLat: result.geometry.location.lat, locLng: result.geometry.location.lng }));
                        let types = ['locality', 'administrative_area_level_2', 'administrative_area_level_1'];
                        for (let i = 0; i < 3; i++) {
                            for (let j = 0; j < result.address_components.length; j++) {
                                if (result.address_components[j].types[0] === types[i]) {
                                    city = result.address_components[j].long_name;
                                    flag = 1;
                                    break;
                                }
                            }
                            if (flag === 1) {
                                if (city === 'Secunderabad') {
                                    city = 'Hyderabad';
                                }
                                if (city === "Bangalore" || city === "Bengaluru Rural" || city === "Bengaluru Urban" || city === "Bangalore Rural" || city === "Bangalore Urban") {
                                    city = "Bengaluru";
                                }
                                self.props.dispatch(fetchLocationByLatLong(city));
                                self.props.dispatch(addFirstStop({ city: city, name: result.formatted_address, latitude: result.geometry.location.lat, longitude: result.geometry.location.lng }));
                                Geocode.fromAddress(city)
                                    .then(response => {
                                        let res = response.results[0];
                                        self.props.dispatch(updateCityOfStop({ city: city, cityLat: res.geometry.location.lat, cityLng: res.geometry.location.lng }, 0));
                                    })
                                break;
                            }
                        }
                        // const address = response.results[0].formatted_address;
                        // console.log(address);
                    },
                        error => {
                            console.error(error);
                        }
                    );
            }
            function error(err) {
                console.warn(`ERROR(${err.code}): ${err.message}`);
                if (err.code === 1) {
                    // alert("Please Enable Location Service to Locate You or Enter Pick Up Location Manually.");
                    // self.props.dispatch(appActions.geoLocationServiceFail());
                }
                else if (err.code === 3) {
                    // alert('Unable to Locate You at this Moment. Please Try Again or Enter Your Location Manually.');
                    // self.props.dispatch(appActions.geoLocationServiceFail());
                }
            }
        }
    }

    toggleTripType() {
        // if(this.state.rentalType === 2){
        //     alert('Round Trip is mandatory for Self Drive Cars');
        //     return;
        // }
        if (this.props.ride.pickup.loc === "") {
            alert('Please enter your pickup address');
            return;
        }
        if (this.props.ride.dest.loc === "" && (this.props.ride.rentalType===1 || this.props.ride.rentalType===3)) {
            alert('Please enter your destination address');
            return;
        }
        let stops = this.props.ride.stops;
        let payLoad = stops[0];
        // console.log(analytics);


        if (this.state.isRoundtrip) {
            // eslint-disable-next-line
            analytics.track('Round Trip', { category: 'clicks' });
            this.props.dispatch(addStop(payLoad));
            this.props.dispatch(updateCityOfStop({ city: payLoad.city, cityLat: payLoad.cityLat, cityLng: payLoad.cityLng }, stops.length));
            this.props.dispatch(updateTripType('Round Trip'));
        }
        else {
            // eslint-disable-next-line
            analytics.track('One Way', { category: 'clicks' });
            this.props.dispatch(removeLastStop())
            this.props.dispatch(updateTripType('One Way'));
        }
    }



    getDistanceBetweenStops() {
        
        console.log("Distance called");
        let self = this;
        if (this.props.stops[0]===undefined && this.props.stops.length <= 2 ){
            return;
        }
        let waypoints = []
        let stops = this.props.stops;
        let origin = new window.google.maps.LatLng(stops[0].latitude,stops[0].longitude)
        let destination = new window.google.maps.LatLng(stops[stops.length - 1].latitude,stops[stops.length - 1].longitude)

        for(let i = 1 ; i < stops.length -1 ;i++){
            let waypoint = {location: new window.google.maps.LatLng(stops[i].latitude,stops[i].longitude),stopover: true};
            waypoints.push(waypoint);
        }

        let request = {
            origin: origin,
            destination: destination,
            waypoints: waypoints,
            travelMode: 'DRIVING'
        };

        //eslint-disable-next-line
        const DirectionsService = new window.google.maps.DirectionsService();
        //eslint-disable-nexxt-line
        DirectionsService.route(request, (result, status) => {
            //eslint-disable-next-line
            console.log(result);
            if (status === "OK") {
                const route = result.routes[0];
                let distance = route.legs.reduce((carry, curr) => {
                    return carry + curr.distance.value;
                    }, 0) / 1000;
                let duration = route.legs.reduce((carry, curr) => {
                    return carry + curr.duration.value;
                    }, 0) / 3600;
                self.props.dispatch(updateRideDistance(parseInt(distance)));
                self.props.dispatch(updateRideDuration(parseInt(duration)));
            } 
            else {
                console.error(`error fetching directions ${result}`);
                }
        });
    }


    handleStartBooking(event) {
        
        // eslint-disable-next-line
        analytics.track('Booking Started', { category: 'clicks' });
        // this.getDistanceBetweenStops();
    }

    componentDidMount() {

        if(window.location.href.includes("hyderabad")) {
            this.setState({tagline: 'in Hyderabad'});

        } 
        else if(window.location.href.includes("bangalore")) {
            this.setState({tagline: 'in Bangalore'})
        }
        let ride = this.props.ride;
        if (ride.pickup.loc !== "") {
            this.setState({ pickupAddress: this.props.ride.pickup.loc });
        }

        if (ride.dest.loc !== "") {
            this.setState({ destinationAddress: this.props.ride.dest.loc });
        }

        if (ride.measureLookupId === '100010001') {
            this.setState({ isRoundtrip: true });
        }

        if(ride.pickup.loc === ""){
            this.useGeolocation();
        }
    }


    handleRentalTypeChange = (type) => {
        let ride = this.props.ride;
        this.setState({ rentalType: type });
        this.props.dispatch(updateRentalType(type));
        if(type === 1){
            this.props.dispatch(updateLeadTime(2.25));
            if(this.props.ride.dest.loc === "" && this.state.isRoundtrip){
                this.setState({ isRoundtrip: false });
                this.props.dispatch(updateTripType('One Way'));
            }
        }
        else if(type === 2){
            this.props.dispatch(updateLeadTime(4));
            this.setState({ isRoundtrip: true, endDate: Moment(this.state.startDate).add(24, 'h'), endTime: this.state.startTime },function(){
                this.props.dispatch(updateRideEndDate(Moment(this.state.endDate).format('DD-MM-YYYY')));
                this.props.dispatch(updateTripType('Round Trip'));
            });
        }
        else if(type === 3){
            
            // eslint-disable-next-line
            analytics.track('Airport Type Chosen',{category:'clicks'});
            this.props.dispatch(updateLeadTime(4));
            // this.setState({ startDate: Moment().add(4,'h'),endDate: Moment(this.state.startDate).add(24, 'h')},function(){
            //     // this.props.dispatch(updateRideEndDate(Moment(this.state.endDate).format('DD-MM-YYYY')));
            //     // this.props.dispatch(updateTripType('Round Trip'));
            // });
        }
        else if(type >= 4){
            this.props.dispatch(updateLeadTime(4));
            if(type===4){
            // eslint-disable-next-line
            analytics.track('Rental Type 2 Hr 20Kms',{category:'clicks'});
                this.setState({ isOverlayActive:false, showHourlyPackageOptions:false, rentalPackage:'2 hrs, 20kms', endDate: Moment(this.state.startDate).add(24, 'h')});
            }
            else if(type===5){
            // eslint-disable-next-line
            analytics.track('Rental Type 4 Hr 40Kms',{category:'clicks'});
                this.setState({ isOverlayActive:false, showHourlyPackageOptions:false, rentalPackage:'4 hrs, 40kms', endDate: Moment(this.state.startDate).add(24, 'h')});
            }
            else if(type===6){
            // eslint-disable-next-line
            analytics.track('Rental Type 8 Hr 80Kms',{category:'clicks'});
                this.setState({ isOverlayActive:false, showHourlyPackageOptions:false, rentalPackage:'8 hrs, 80kms', endDate: Moment(this.state.startDate).add(24, 'h')});
            }
            else if(type===7){
                // eslint-disable-next-line
                analytics.track('Rental Type 12 Hr 120Kms',{category:'clicks'});
                    this.setState({ isOverlayActive:false, showHourlyPackageOptions:false, rentalPackage:'12 hrs, 120kms', endDate: Moment(this.state.startDate).add(24, 'h')});
                }
        }
    }

    componentDidUpdate(prevProps, prevState){
        // console.log("KJAE<FBJKEA<BM");
        if(this.props.ride.leadTime !== prevProps.ride.leadTime){
            let ride = this.props.ride;
        // this.useGeolocation();
            // let startDates = [], endDates=[];
            let today = Moment();
            let minutes = 30 - (today.minute() % 30);
            let leadTime = this.props.ride.leadTime;
            // let leadTime = 2;
            let minStartDate = Moment().add(leadTime, 'h').add(minutes, 'm').seconds('0');
            let startDate = Moment().add(leadTime, 'h').add(minutes, 'm').seconds('0');
            this.setState({ minStartDate });
            
            if(Moment(ride.startDate, 'DD-MM-YYYY').hours(ride.startTime.split(':')[0]).minutes(ride.startTime.split(':')[1]).diff(Moment(), 'h') < this.props.ride.leadTime){
                this.setState({ startDate, startTime: Moment(startDate).format("hh:mm A") }, function(){
                    this.props.dispatch(updateStartTime(convertTime12to24(this.state.startTime)));
                });
                this.props.dispatch(updateRideStartDate(Moment(startDate).format("DD-MM-YYYY")));
            }

            let minEndDate = Moment(minStartDate).add(2,'h');
            let endDate = Moment(ride.startDate, 'DD-MM-YYYY').hours(23).minutes(30);
            this.setState({ minEndDate });

            this.setState({ endDate, endTime: Moment(endDate).format("hh:mm A") }, function(){
                this.props.dispatch(updateEndTime(convertTime12to24(this.state.endTime)));
            });
            this.props.dispatch(updateRideEndDate(Moment(endDate).format("DD-MM-YYYY")));

            // if(ride.startDate === Moment().format("DD-MM-YYYY")){
            //     if(Moment(ride.startTime, "HH:mm:ss").diff(Moment(),'h') < this.props.ride.leadTime){
                    
            //     }
            // }
        
            // for(let i=0;i < 100; i++){
            //     startDates.push(Moment(minStartDate).add(i, 'd'));
            // }
            let startTime = Moment(minStartDate).format("HH:mm");
            // let startTimes = timelineLabels(startTime,30,'m');
            // this.setState({ startDates, startTimes });




            // for(let i=0;i < 100; i++){
            //     endDates.push(Moment(minEndDate).add(i, 'd'));
            // }
            // let endTime = Moment(minEndDate).format("HH:mm");
            // let endTimes = timelineLabels(endTime,30,'m');
            // this.setState({ endDates, endTimes });
        }

        if(prevProps.stops !== this.props.stops && this.props.stops.length > 1){
            this.getDistanceBetweenStops();
        }
    }

    render() {
        let ride = this.props.ride;
        return (
            <section className={styles.section}>
                <div className={styles.row}>
                    <div className={css`${tw`w-full lg:w-2/3`}; margin-top: 33vh;`}>
                        <h1 className={styles.mainHeading}>Have a Destination in Mind?</h1>
                        <p className={css`${tw`block text-lg text-grey-dark`}`}>We have all sorts of rental cars/cabs for you {this.state.tagline}</p> 
                        
                    </div>
                    <div className={css`${tw`flex flex-no-wrap w-full justify-between overflow-x-scroll scrolling-touch mt-12 mb-4`}`}>
                    <RentingChoiceTab
                            active={this.state.rentalType >= 4}
                            icon={HRCabIcon}
                            onClick={() => this.handleRentalTypeChange(5)}>
                            Hourly Rental
                        </RentingChoiceTab>
                        <RentingChoiceTab 
                            active={this.props.ride.rentalType === 1}
                            icon={OSCabIcon}
                            onClick={() => this.handleRentalTypeChange(1)}>
                            outstation</RentingChoiceTab>
                        <RentingChoiceTab
                            active={this.props.ride.rentalType === 3}
                            icon={ATCabIcon}
                            onClick={() => this.handleRentalTypeChange(3)}>
                            Airport Transfer
                        </RentingChoiceTab>
                        
                        {/* <RentingChoiceTab
                            active={this.props.ride.rentalType === 2}
                            icon={SDCabIcon}
                            onClick={() => this.handleRentalTypeChange(2)}>
                            Self Drive Cars
                        </RentingChoiceTab> */}
                        
                    </div>
                   
                </div>
                <div className={styles.bookingForm}>
                    <div className={css`${tw`flex flex-wrap items-center justify-between w-full border-b-2 lg:h-gr2/3`};  border-bottom: 1px solid rgba(0,0,0,0.1);`}>
                        <p onClick={() => this.handleFormEdit("pickup")}
                            className={css`${tw`inline-flex items-center relative font-sans w-full lg:w-2/5 text-grey-darker px-8 lg:px-12 py-6 hover:bg-grey-light cursor-pointer truncate`}`}>
                            {/* Start Location */}
                            <div className={css`${tw`h-6 w-6 rounded-full bg-purple mr-2`}`}></div>
                            <span className={css`${tw`block text-lg truncate lg:text-xl font-brand font-bold`}`}>
                                {this.props.ride.pickup.loc === "" ? "Enter Pickup" : this.props.ride.pickup.loc}
                            </span>
                        </p>
                        {/* removing destination field for Self Drive */}
                        {this.props.ride.rentalType !== 2 && this.props.ride.rentalType <= 3 &&
                            <p onClick={() => this.handleFormEdit("dest")}
                                className={css`${tw`inline-flex items-center relative font-sans w-full lg:w-2/5 text-grey-darker px-8 lg:px-12 py-6 hover:bg-grey-light cursor-pointer truncate`}`}>
                                {/* Destination Location */}
                                <div className={css`${tw`h-6 w-6 rounded-full bg-purple mr-2`}`}></div>
                                <span className={css`${tw`block text-lg truncate lg:text-xl font-brand font-bold`}`}>
                                    {this.props.ride.dest.loc === "" ? "Enter Destination" : this.props.ride.dest.loc}
                                </span>
                            </p>
                        }
                    </div>
                    <div className={css`${tw`flex flex-wrap w-full h-auto lg:h-gr1/3 border-b-2`};`}>
                        <div className={css`${tw`flex flex-wrap justify-between w-full lg:w-gr2/3 pl-6 lg:pl-12`}`}>
                            <div onClick={(e) => { this.handleFormEdit('startDate') }} className={css`${tw`flex items-center w-2/3 lg:w-1/3 px-1 py-2 my-2 hover:bg-grey-light cursor-pointer`}`}>
                                {Moment(ride.startTime, "HH:mm A").format('HH').valueOf() >= 6 && Moment(ride.startTime, "HH:mm A").format('HH').valueOf() <= 18 ?
                                    <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunrise.svg')} />
                                    :
                                    <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunset.svg')} />
                                }
                                <div className={css`${tw`ml-2`}`}>
                                    <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>Start at</p>
                                    <p className={css`${tw`font-medium text-grey-darker`}`}>{Moment(ride.startDate, "DD-MM-YYYY").format("DD MMM")}, {Moment(ride.startTime, "HH:mm:ss").format("hh:mm A")}</p>
                                </div>

                            </div>
                            {this.state.isRoundtrip && this.props.ride.rentalType < 3 && <div onClick={(e) => { this.handleFormEdit('endDate') }} className={css`${tw`flex items-center w-2/3 lg:w-1/3 px-1 py-2 my-2 hover:bg-grey-light cursor-pointer`}`}>
                                {Moment(ride.endTime, "HH:mm A").format('HH').valueOf() >= 6 && Moment(ride.endTime, "HH:mm A").format('HH').valueOf() <= 18 ?
                                    <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunrise.svg')} />
                                    :
                                    <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunset.svg')} />
                                }
                                <div className={css`${tw`ml-2`}`}>
                                    <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>End at</p>
                                    <p className={css`${tw`font-medium text-grey-darker`}`}>{Moment(ride.endDate, "DD-MM-YYYY").format("DD MMM")}, {Moment(ride.endTime, "HH:mm:ss").format("hh:mm A")}</p>
                                </div>

                            </div>}
                            {this.props.ride.rentalType !== 2 && this.props.ride.rentalType < 3 &&

                                <div onClick={(e) => { this.handleFormEdit('tripType') }}
                                    className={css`${tw`flex items-center w-2/3 lg:w-1/3 px-1 py-2 my-2 hover:bg-grey-light cursor-pointer`}`}>
                                    <img className={css`${tw`h-8`}`} src={require('../components/SvgComponents/roundtrip.svg')} />
                                    <div className={css`${tw`ml-2`}`}>
                                        <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>Trip Type</p>
                                        <p className={css`${tw`font-medium text-grey-darker`}`}>{this.state.isRoundtrip ? 'Roundtrip' : 'One Way'}</p>
                                    </div>

                                </div>}
                            {this.props.ride.rentalType >= 4 &&
                                <div onClick={() => this.setState({ showHourlyPackageOptions: true, isOverlayActive: true, isDestActive: false, isPickupActive: false })}
                                    className={css`${tw`flex items-center w-2/3 lg:w-1/3 px-1 py-2 my-2 hover:bg-grey-light cursor-pointer`}`}>
                                    {/* <img className={css`${tw`h-8`}`} src={require('../components/SvgComponents/roundtrip.svg')} /> */}
                                    <div className={css`${tw`ml-2`}`}>
                                        <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>Package</p>
                                        <p className={css`${tw`font-medium text-grey-darker`}`}>
                                            {this.state.rentalPackage}
                                        </p>
                                    </div>

                                </div>

                            }

                        </div>
                        <div onClick={(e) => this.handleStartBooking(e)} className={css`${tw`relative h-full flex items-center w-full lg:w-gr1/3 pr-6 lg:pr-12`}; background: linear-gradient(200deg, #AA0BCA 0%, #7D0EDE 40%), #7D0EDE;`}>
                            <AniLink to="/carsList" hex="#8800dd"
                                entry={{
                                    // delay: 1,
                                    state: { pass: 'Whatever you want', to: 'the next page' },
                                }}
                                exit={{
                                    delay: 1,
                                    state: { pass: 'Whatever you want', fromexit: 'the next page' },
                                }}
                                className={css`${tw`no-underline flex items-center pl-8 text-white font-bold min-h-16 py-8 h-full w-full`}`}>
                                Start Booking
                                </AniLink>
                        </div>
                    </div>
                </div>

                <div className={css`${tw`w-full flex justify-between items-center flex-wrap mt-12 max-w-row mt-16`}`}>
                    <Link className={css`${tw`w-full lg:w-auto no-underline text-grey-dark py-4 hover:bg-grey-light rounded-sm hover:text-purple px-2 font-medium`}`} to="/terms/">Terms &amp; Conditions</Link>
                    <a className={css`${tw`w-full lg:w-auto flex flex-wrap justify-end no-underline text-grey-dark py-4 hover:bg-grey-light rounded-sm hover:text-purple px-2 lg:text-right font-medium`}`} 
                        href="tel:+918688901902">
                        <p className={css`${tw`opacity-50 w-full`}`}>Call Us On</p>
                        <p className={css`${tw`w-full`}`}>+91 8688 901 902</p>
                    </a>
                </div>
                
                <div className={css`
                    ${tw`absolute pin-t z-back h-full w-full`}; 
                    background-image: url(${HeroImage});
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    `}>

                </div>
                {/* <img className={css`${tw`absolute pin-t z-back`}; top: -20vh;`} src={require('../assets/img/')} alt="Zyppys" /> */}

                <BlackOverlay onClick={() => this.setState({ isOverlayActive: false, isSliderModalActive: false, showHourlyPackageOptions: false })} active={this.state.isOverlayActive} >
                    {(this.state.isDestActive || this.state.isPickupActive) && 
                        <PlacesModal 
                            active={this.state.isSliderModalActive} 
                            isPickup={this.state.isPickupActive} 
                            isDest={this.state.isDestActive} 
                            onClick={()=>this.setState({isOverlayActive: false,isSliderModalActive:false})}
                            showHourlyPackageOptions = {this.state.showHourlyPackageOptions}
                            isRoundtrip = {this.state.isRoundtrip}
                        />
                    }
                    {(this.state.editingStartDate||this.state.editingEndDate) &&
                        <DateTime active={this.state.isSliderModalActive} isStartDate={this.state.editingStartDate} onClick={()=>this.setState({isOverlayActive: false,isSliderModalActive:false})}/>
                    }
                    {this.state.showHourlyPackageOptions &&

                        <SliderModal active={this.state.showHourlyPackageOptions}>
                        <div className={css`${tw`w-full flex items-center flex-wrap px-6 py-8`}`}>
                                <h3 className={css`${tw`w-full lg:px-8 font-light lg:text-3xl tracking-tight mb-2 text-grey`}`}>
                                    How long do you need the rental? <br />
                                </h3>  
                                <div className={css`${tw`w-full flex items-start lg:px-8`}`}>
                                    <ul className={css`${tw`list-reset w-full`}`}>
                                        {/* <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} onClick={()=>this.handleRentalTypeChange(4)}>2 hrs, 20kms</li> */}
                                        {/* <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} onClick={()=>this.handleRentalTypeChange(5)}>4 hrs, 40kms</li> */}
                                        <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} onClick={()=>this.handleRentalTypeChange(6)}>8 hrs, 80kms</li>
                                        {/* <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} onClick={()=>this.handleRentalTypeChange(7)}>12 hrs, 120kms</li> */}

                                    </ul>
                                </div>
                            </div>

                        </SliderModal>
                    }
                </BlackOverlay>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    ride: state.ride,
    stops: state.ride.stops,
    isUserLoggedIn: state.user.isLoggedIn,
})


export default connect(mapStateToProps)(SectionOne);

