import React from 'react';
import {css} from 'emotion';
import styled from 'styled-components';
import SliderModal from './SliderModal';
import RentingChoiceButton from './RentingChoiceButton';

import OSCabIcon from '../../assets/img/illustrations/outstationRentals.svg';
import SDCabIcon from '../../assets/img/illustrations/selfDrivenRentals.svg'
import ATCabIcon from '../../assets/img/illustrations/airportTransfer.svg'
import HRCabIcon from '../../assets/img/illustrations/hourlyRentals.svg'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

/* global tw */
const RentingChoicesWrapper = styled.div`
  ${tw`mx-auto lg:-mx-2 px-8 lg:px-6 pt-2 relative z-0 max-h-full w-full overflow-y-scroll overflow-x-hidden scrolling-touch`};
  -webkit-overflow-scrolling: touch;
`

const RentingChoicesModal = (props) => (
    <SliderModal active={props.active}>
        <RentingChoicesWrapper>
            <h3 className={css`${tw`w-full text-xl tracking-tight font-medium text-black text-grey-dark sticky`}`}>What's your <span className={css`${tw`block font-bold text-purple`}`}>Choice of Rental?</span></h3>
            <RentingChoiceButton
                onClick={() => props.onClick(6, true)}
                icon={HRCabIcon}
                title='Hourly'
                description='I want a chauffeur driven car to stay with me for a certain duration to roam inside the city.'
            />
            <RentingChoiceButton
                onClick={() => props.onClick(1, true)}
                icon={OSCabIcon}
                title='Outstation'
                description='I wanna travel between cities driven by a chauffeur'
            />
            <RentingChoiceButton
                onClick={() => props.onClick(3, true)}
                icon={ATCabIcon}
                title='Airport'
                description='I want to get to an airport (or) get home from an airport in a chauffeur driven car.'

            />
             {/* <RentingChoiceButton
                onClick={() => props.onClick(2, true)}
                icon={SDCabIcon}
                title='Self Drive Cars'
                description='I want to rent a car and drive myself, and go anywhere I want.'

            /> */}
           
        </RentingChoicesWrapper>
        {props.hasFilter  && 
            <p>Show Filters Here</p>
        }
    </SliderModal>
);

export default RentingChoicesModal;